<template>
<v-dialog :value="value" persistent width="500">
  <v-card>
    <v-toolbar flat dark color="#1C3144">
      <v-btn icon dark @click="$emit('update:value', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      <v-toolbar-title>Misc Maintenance</v-toolbar-title>
    </v-toolbar>
      <v-list>
        <v-list-item v-for="i in options" :key="i.path" @click="selectTask(i.path)">
          {{i.name}}
        </v-list-item>
      </v-list>
  </v-card>
</v-dialog>
</template>

<script>

export default {
  name: 'Maintenance',
  data () {
    return {
      admin: false,
      options: [
        { name: 'Ad Group Maintenance', id: 15, path: '/maintenance/ad-groups' },
        { name: 'Owner Group Maintenance', id: 17, path: '/maintenance/owners' },
        { name: 'Feature Type Maintenance', id: 30, path: '/maintenance/feature-types' },
        { name: 'Customer/Store Information Maintenance', id: 40, path: '/maintenance/stores' },
        { name: 'Sub-Group Maintenance', id: 75, path: '/maintenance/sub-groups' },
        { name: 'Manage AWG Vendor Codes', id: 99, path: '/maintenance/vendor-codes' },
        { name: 'Price Book Maintenance', id: 99, path: '/maintenance/price-book' }
        // { name: 'Import Perishable and Best Choice TPRs', id: 5 },
        // { name: 'Recap - Addition Maintenance', id: 10 },
        // { name: 'TPR Change Form', id: 70 },
        // { name: 'Ad-item duplicates for a selected ad', id: 84 },
        // { name: 'Special Billing', id: 105 },
        // { name: 'nTouch Store Maintenance', id: 110 },
        // { name: 'TPR-Ad Group Bulk Load/Change', id: 115 }
      ]
    }
  },
  props: [
    'value'
  ],
  methods: {
    selectTask (value) {
      this.$emit('update:value', false)
      this.$router.push({ path: value })
    }
  }
}
</script>
<style scoped>
.toolbar {
  z-index: 10;
}
</style>
